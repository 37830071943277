<div class="body" id="blossom_container">
  <div class="spinner" *ngIf="showSpinner">
    <img [src]="'assets/img/'+spinnerName+'.gif'"/>
    <p>
      <span class="h1" *ngIf="correctAnswer != ''">✅ {{correctAnswer}}</span>
    </p>
  </div>
  <div class="trophy" *ngIf="finished">
    <img [src]="'assets/img/winner.gif'" style="float: left;"/>
    <div class="trophy-text">
      <h1 *ngFor="let winner of winners">{{winner.name}}!!</h1>
    </div>
  </div>
  <img src="assets/img/logo.png" class="logo">
  <h1 *ngIf="!started">Monthly OP Meeting - Libra Edition<br/> <small>{{title}}</small></h1>

  <h2  *ngIf="!started">Player {{ppl.length}}, {{qTruth.size}}<span (click)="toggleDebug()">+</span>{{qDare.size}}
    questions</h2>

  <form (ngSubmit)="start()" *ngIf="!started">

    <div>
      <button class="myButton" type="submit">🥰 Start</button>
    </div>
  </form>

  <div *ngIf="started">
    <!--    <div class="drawbtn" *ngIf="!currentQ">-->
    <!--      <a class="myButton" (click)="nextPpl()">🎲 Draw!</a>-->
    <!--    </div>-->
    <div *ngIf="currentPpid>=0">
      <!--      <p class="pname">{{ppl.get(currentPpid).name}}</p>-->
      <input class="name-input" [(ngModel)]="ppl[currentPpid].name" placeholder="Player Name"/>
    </div>
    <div class="q-container" *ngIf="currentQ">

      <div #imgOrV>
        <div *ngIf="currentQType=='penalty'" class="penalty"></div>
        <img class="qimg" *ngIf="currentQ.img && !currentQ.img.includes('youtube')" [src]="currentQ.img"/>
        <iframe width="560" height="315" *ngIf="currentQ.img && currentQ.img.includes('youtube')"
                [src]="currentQ.img | safe" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </div>
      <div>
        <span class="q">{{currentQ.q}}</span>
      </div>

      <div #scoreBtns>
        <a class="myButton" (click)="chooseTypeOfQ(currentQType)">🔃</a>
        <a class="myButton" *ngIf="currentQType=='dare' " (click)="scoreAndNextPpl(darePt)">🎉</a>
        <a class="myButton" *ngIf=" currentQType=='penalty'" (click)="scoreAndNextPpl(penaltyPt)">🎉</a>
        <ng-container *ngIf="currentQType=='truth'">
          <a *ngFor="let ans of getShuffledAnswers(currentQ.answers);index as index; trackBy:trackByIndex"
             class="myButton" (click)="answer(ans)">❓ {{ans}}</a>
        </ng-container>
      </div>
    </div>

    <div class="qtypebtns" *ngIf="currentPpid>=0 && !currentQ">
      <a class="myButton" (click)="chooseTypeOfQ('truth')">🤔 Truth!</a>
      <a class="myButton" (click)="chooseTypeOfQ('dare')">💃 Dare!</a>
    </div>
  </div>


  <div class="debug" *ngIf="debug">
    <p>
      currentppid: {{currentPpid}}
    </p>
    <!--<h2 *ngIf="currentPpid">{{ppl.get(currentPpid).name}}</h2>-->

    <p>
      currentq: {{currentQ}}
    </p>


    <p>drawn: <span *ngFor="let dp of drawnPpl">{{dp}}</span></p>
    <div>
      <h3>Questions</h3>
      <h4>Truth</h4>
      <ul class="questions">
        <li *ngFor="let q of qTruth">
          <span class="badge">{{q.score}}</span><strong> {{q.type}}</strong> {{q.q}}
        </li>
      </ul>
      <h4>Dare</h4>
      <ul>
        <li *ngFor="let q of qDare">
          <span class="badge">{{q.score}}</span><strong> {{q.type}}</strong> {{q.q}}
        </li>
      </ul>
    </div>


    <div class="scoreboard">
      <h3>PPL</h3>
      <table class="score">
        <thead>
        <tr>
          <th>Name</th>
          <th>Score</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let p of ppl ">
          <th>{{p.name}}</th>
          <th>{{p.score}}</th>
        </tr>
        </tbody>
      </table>
    </div>

  </div>

  <div class="scoreboard" *ngIf="showScoreBoard">
    <table class="score">
      <thead>
      <tr>
        <th>Name</th>
        <th>Score</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let p of ppl">
        <th>{{p.name}}</th>
        <th>{{p.score}}</th>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<footer>
  <a class="myButton" (click)="toggleScoreBoard()">ScoreBoard</a>
  <a class="myButton" (click)="finish()">🏁</a>
<!--  <div><img src="assets/img/petal.gif" /> </div>-->
  <!--  <a class="myButton" (click)="startTimer()">-->
  <!--    ⏳-->
  <!--    <span *ngIf="timeLeft===originalTimeLeft">Timer</span>-->
  <!--    <span *ngIf="timeLeft<originalTimeLeft">{{timeLeft}}</span>-->
  <!--  </a>-->
</footer>
