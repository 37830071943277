import * as _ from 'lodash';
import {Component, OnInit} from '@angular/core';
import QUESTIONS from './questions.json';
import {timer} from 'rxjs';


interface Question {
  q: string;
  type: string;
  score: string;
  img: string;
  answers: string[];
}

interface Person {
  name: string;
  score: number;
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'Truth OR Dare';
  truthPt = 2;
  darePt = 3;
  penaltyPt = 1;
  // np = 10;
  sa = '';
  qTruth: Set<Question> = new Set();
  qDare: Set<Question> = new Set();
  qPenalty: Set<Question> = new Set();
  drawnPpl: Set<number> = new Set();
  ppl = new Array<Person>();

  currentPpid: number = null;
  currentQ: Question = null;
  currentQType: string;
  showScoreBoard = false;
  selectFrom: Set<Question> = null;
  debug = false;
  originalTimeLeft = 60;
  timeLeft: number = this.originalTimeLeft;
  started: boolean;
  showSpinner = false;
  spinnerName = 'spinner';
  finished = false;
  winners: Person[];
  correctAnswer: string;


  ngOnInit(): void {
    for (const q of QUESTIONS) {
      if (q.type.toLowerCase() === 'truth') {
        q.answers = q.answers.split('|');
        this.qTruth.add(q);
      } else if (q.type.toLowerCase() === 'dare') {
        this.qDare.add(q);
      } else if (q.type.toLowerCase() === 'penalty') {
        this.qPenalty.add(q);
      }
    }

  }


  start(): void {
    this.started = true;
    this.ppl = new Array<Person>();
    this.nextPpl();
  }

  finish(): void {
    this.showScoreBoard = true;
    // sort ppl by object.score
    this.ppl.sort((a, b) => {
      return b.score - a.score;
    });
    this.winners = this.ppl.filter(p => p.score === this.ppl[0].score);
    this.finished = true;
  }

  _getRandomKey(collection): any {
    let keys = Array.from(collection.keys());
    keys = keys.filter((el: number) => !Array.from(this.drawnPpl.keys()).includes(el));
    return keys[Math.floor(Math.random() * keys.length)];
  }

  public activateSpinner(spinnerName: string, correctAnswer= ''): void {
    this.showSpinner = true;
    this.spinnerName = spinnerName;
    this.correctAnswer = correctAnswer;
    let spintime = 6000;
    if (spinnerName === 'penalty') {
      spintime = 6000;
    }

    const t = timer(spintime); // 5000 millisecond means 5 seconds
    t.subscribe(() => {
      // set showloader to false to hide loading div from view after 5 seconds
      this.showSpinner = false;
    });
  }

  nextPpl(): void | number {
    this.ppl.push({name: '', score: 0});
    this.currentPpid = this.ppl.length - 1;
  }


  chooseTypeOfQ(qType: string): void {
    // todo Animation
    if (this.currentPpid < 0) {
      alert('he???');
      return;
    }
    if (!this.ppl[this.currentPpid].name){
      alert('Please enter player name');
      return;
    }

    if (qType.toLowerCase() === 'truth') {
      this.activateSpinner('spinner');
      this.selectFrom = this.qTruth;
    } else if (qType.toLowerCase() === 'dare') {
      this.activateSpinner('spinner');
      this.selectFrom = this.qDare;
    } else if (qType.toLowerCase() === 'penalty') {
      this.selectFrom = this.qPenalty;
    } else {
      // missing
      this.drawnPpl.add(this.currentPpid);
      this.resetCurrent();
      return;
    }
    if (!this.selectFrom.size) {
      alert('We run out of ' + qType + '...');
      return;
    }
    this.currentQ = this._getRandomKey(this.selectFrom);
    this.currentQType = qType;
  }

  toggleScoreBoard(): void {
    this.showScoreBoard = !this.showScoreBoard;
  }

  scoreAndNextPpl(pt: number): void {
    this.activateSpinner('tada');
    this.ppl[this.currentPpid].score += pt;
    this.selectFrom.delete(this.currentQ);
    this.drawnPpl.add(this.currentPpid);
    this.resetCurrent();
  }

  scoreAndPenalty(pt: number, correctAnswer= ''): void {
    this.activateSpinner('fail', correctAnswer);
    this.selectFrom.delete(this.currentQ);
    this.ppl[this.currentPpid].score += pt;
    this.chooseTypeOfQ('penalty');
  }

  private resetCurrent(): void {
    // this.currentPpid = null;
    this.currentQ = null;
    this.selectFrom = null;
    this.nextPpl();
  }

  toggleDebug(): void {
    this.debug = !this.debug;
  }


  answer(ans: string): void {
    if (ans === this.currentQ.answers[0]) {
      this.scoreAndNextPpl(this.truthPt);
      return;
    }

    this.scoreAndPenalty(0, this.currentQ.answers[0]);
  }

  getShuffledAnswers(answers: string[]): string[] {
    return _.shuffle(answers);
  }

  trackByIndex(index, item): number {
    console.log(index);
    return index;
  }
}
